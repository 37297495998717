import React, {lazy, Suspense} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Media from 'react-media';
import {PageName, PageRoute} from "./types";
import {getPageOptions} from "./utils/page-options";
import {CircularProgress} from "@mui/material";

const Home = lazy(() => import("./pages/Home"));
const Instructions = lazy(() => import("./pages/Instructions"));
const SightTest = lazy(() => import("./pages/SightTest"));
const NextSteps = lazy(() => import("./pages/NextSteps"));

export function App() {
    return (
        <BrowserRouter>
            <Suspense fallback={<CircularProgress/>}>
                <Media query="(min-width: 599px)">
                    {matches =>
                        matches
                            ? (<Routes>
                                <Route path={PageRoute.Home} element={<Home options={getPageOptions(PageName.HOME)}/>}/>
                                <Route path={PageRoute.SightTest}
                                       element={<SightTest options={getPageOptions(PageName.SIGHT_TEST, true, false)}/>}/>
                            </Routes>)
                            : (<Routes>
                                <Route path={PageRoute.Home} element={<Home options={getPageOptions(PageName.HOME)}/>}/>
                                <Route path={PageRoute.Instructions}
                                       element={<Instructions options={getPageOptions(PageName.INSTRUCTIONS)}/>}/>
                                <Route path={PageRoute.SightTest}
                                       element={<SightTest options={getPageOptions(PageName.SIGHT_TEST, false, true)}/>}/>
                                <Route path={PageRoute.NextSteps}
                                       element={<NextSteps options={getPageOptions(PageName.NEXT_STEPS)}/>}/>
                            </Routes>)}
                </Media>
            </Suspense>
        </BrowserRouter>
    );
}
