export enum PageRoute {
    // Internal
    Home = '/*',
    Instructions = '/instructions',
    SightTest = '/test',
    NextSteps = '/next-steps',
    // External
    OcuPlanAmd = 'https://www.ocuplan.co.uk/amd',

}

export interface PageProps {
    options: PageOptions
}

export interface PageOptions {
    nextPage?: PageRoute,
    previousPage?: PageRoute,
    playSound?: boolean,
    appBar: {
        show?: boolean,
        enableBack?: boolean,
        enableSkip?: boolean,
    },
    button: {
        text?: string,
        show?: boolean
    }
}

export enum PageName  {
    HOME = 'Home',
    INSTRUCTIONS = 'Instructions',
    SIGHT_TEST = 'SightTest',
    NEXT_STEPS = 'NextSteps',
}
