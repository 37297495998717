import {red} from "@mui/material/colors";
import {createTheme} from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#008ff5",
            dark: "#0443A2"
        },
        secondary: {
            main: "#3A8F4B",
        },
        error: {
            main: red.A400,
        },
    },
});

export default theme;
