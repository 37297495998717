import {PageName, PageOptions, PageRoute} from "../types";

export function getPageOptions(page: PageName, playSound = false, showAppBar = false): PageOptions {
    switch (page) {
        case PageName.HOME:
            return {} as PageOptions
        case PageName.INSTRUCTIONS:
            return {
                appBar: {
                    show: true,
                    enableSkip: true
                },
                button: {
                    text: 'Continue',
                    show: true
                },
                nextPage: PageRoute.SightTest,
                playSound: true
            }
        case PageName.SIGHT_TEST:
            return {
                appBar: {
                    show: showAppBar,
                    enableBack: true
                },
                button: {
                    text: 'Continue',
                    show: showAppBar
                },
                previousPage: PageRoute.Instructions,
                nextPage: PageRoute.NextSteps,
                playSound,
            }
        case PageName.NEXT_STEPS:
            return {
                appBar: {
                    show: true,
                    enableBack: true
                },
                button: {
                    text: 'Find out more',
                    show: true
                },
                previousPage: PageRoute.SightTest,
                nextPage: PageRoute.OcuPlanAmd,
                playSound: true
            }
    }
}
